import React from 'react'
import style from "../style/edit.module.css";
const CachingEditButtons = ({handleCancel,handleSubmitEdit}:any) => {
  return (
    <div className={style.chachingButtons}>
        <button className={style.save} onClick={handleSubmitEdit}> Save </button>
        <button className={style.cancel} onClick={handleCancel}> Cancel</button>

    </div>
  )
}

export default CachingEditButtons