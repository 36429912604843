import { configureStore } from "@reduxjs/toolkit";
import { regLoginSliceReducer } from "../slice/regLogin/regLoginSlice";
import { useDispatch } from 'react-redux'
import { cachingSliceReducer } from "../slice/caching/cachingSlice";
import { reparoSliceReducer } from "../slice/reparo/reparoSlice";
import { imageSliceReducer } from "../slice/image/imageSlice";
import { compressionSliceReducer } from "../slice/compression/compressionSlice";

export const store =configureStore({
    reducer:{
        users:regLoginSliceReducer,
        caching:cachingSliceReducer,
        reparo:reparoSliceReducer,
        image:imageSliceReducer,
        compression:compressionSliceReducer

    },
    devTools:true
})
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const usedispatch: () => AppDispatch = useDispatch 