import React from 'react'
import { Outlet } from 'react-router'
import SettingsPagination from './settingsPage/SettingsPagination'
import SettingsTitle from './settingsPage/SettingsTitle'
import style from "./style/settings.module.css"

const Settings = () => {

  return (
    <div className={style.settings}>
      <SettingsTitle />
      <SettingsPagination />
      <Outlet />
    </div>
  )
}

export default Settings