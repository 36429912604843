import React, { useEffect, useState } from "react";

import {
 
  usedispatch,
} from "../../../../../../component/features/store/store";
import { EditRule, EditSendRule, GetAllRulePage } from "../../../../../../component/features/slice/caching/cachingApi";
import style from "./style/edit.module.css";
import CachingEditSelect from "./cachingEditType/CachingEditSelect";
import { domain_id, token, page_id } from "../../../../../globalState";


const CachingEdit = ({ open, setOpen }: any) => {
;

  const [ruleUpdate, setRuleUpdate] = useState({
    domain_id: domain_id,
    page_id: page_id,
    url: "",
    browser_cache_ttl: "",
    cache_level: "",
    edge_cache_ttl: "",

    status: "disabled",
  });
  const dispatch = usedispatch();


  useEffect(() => {
   
    dispatch(EditRule({ domain_id, token, page_id }));
  }, []);

  const [isCheck, setIsCheck] = React.useState(false);

 
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRuleUpdate({ ...ruleUpdate, [e.target.name]: e.target.value });
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRuleUpdate({ ...ruleUpdate, [e.target.name]: e.target.value });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmitEdit = () => {
    const domain_id: number = Number(localStorage.getItem("id"));
    const explicit_cache_control = isCheck ? "on" : "off"
    const newObj = {...ruleUpdate,explicit_cache_control}
    dispatch(EditSendRule(newObj)).unwrap().then(() => {
      dispatch(GetAllRulePage({ domain_id, token }));
      setOpen(false)
    })
  }

  return (
    <div className={style.editRule}>
      <CachingEditSelect
        isCheck={isCheck}
        ruleUpdate={ruleUpdate}
        onChangeInput={handleChangeInput}
        onChangeSelect={handleChangeSelect}
        handleCancel={handleCancel}
        handleSubmitEdit={handleSubmitEdit}
        setIsCheck={setIsCheck}
      />
    </div>
  );
};

export default CachingEdit;
