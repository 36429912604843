import { createSlice, current } from "@reduxjs/toolkit";
import { CreateRule, DeleteUlr, EditRule, EditSendRule, GetAllRulePage, GetCacheBrowser } from "./cachingApi";
import { CachingBrowserCache } from "./cachingInterface";
import { editRuleType } from '../reparo/reparoTypes';

const initialState: any = {
  browserCache: [],
  cachLevel: [],
  edge: [],
  explicit: [],
  getAllRule: [],
  editRule:[],
  isLoading: false,
  isLoadingCreateRule:false,
  isLoadingDelete:false,
  isLoadingEditRule:false
};

const cachingSlice = createSlice({
  name: "caching",
  initialState,
  reducers: {
    createRule:(state,action)=>{
      
      state.getAllRule.push({...action.payload})

     
    },

    deleteRuleURL: (state, action) => {
      state.getAllRule = state.getAllRule.filter(({ id }: any) => {
        return id !== action.payload;
      });
     
    },
   
  },
  extraReducers: (bulider) => {
    bulider
      .addCase(GetCacheBrowser.fulfilled, (state, action) => {
        const browser: CachingBrowserCache = action.payload.browser_cache_ttl;
        const level: CachingBrowserCache = action.payload.cache_level;
        const edges: CachingBrowserCache = action.payload.edge_cache_ttl;
        const explicits = action.payload.explicit_cache_control;

        state.browserCache = browser;
        state.cachLevel = level;
        state.edge = edges;
        state.explicit = explicits;
      })

      .addCase(GetAllRulePage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllRulePage.fulfilled, (state, action) => {
        state.isLoading = false;
        if(action.payload) {
          state.getAllRule = action.payload;
        }
        
      })
      .addCase(CreateRule.pending,(state)=>{
        state.isLoadingCreateRule = true
      })
      .addCase(CreateRule.fulfilled,(state)=>{
        state.isLoadingCreateRule = false
      })
      .addCase(DeleteUlr.pending,(state)=>{
        state.isLoadingDelete = true
      })
      .addCase(DeleteUlr.fulfilled,(state)=>{
        state.isLoadingDelete = false
      })
      .addCase(EditRule.fulfilled, (state, action) => {
        state.editRule = [action.payload]
      })
      .addCase(EditSendRule.pending,(state)=>{
          state.isLoadingEditRule = true
      })

      .addCase(EditSendRule.fulfilled, (state, action) => {
       
        // state.getAllRule = [...state.getAllRule].filter((e: any) => {
        //   if (e.id === action.payload.id) {
        //     return { ...action.payload }
        //   }
        //   return e
        // })
        // state.getAllRule = [{ ...state.getAllRule }, { ...action.payload }]
        state.isLoadingEditRule = false
      })
      
  },
});
export const { deleteRuleURL, createRule } = cachingSlice.actions;
export const cachingSliceReducer = cachingSlice.reducer;



