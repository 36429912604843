import React, {  useState } from "react";
import style from "../style/login.module.css";
import { Link,useNavigate } from "react-router-dom";
import view from "../../images/signup/view.png";

import { usedispatch } from "../../features/store/store";
import { LoginUser } from "../../features/slice/regLogin/regLoginThunk/regApi";

import { LoginDto, LoginUserResponse } from "../../features/types/loginApi";

interface LoginProps {
  data: LoginDto
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const LoginForm = ({ data, onChange }: LoginProps) => {
  const dispatch = usedispatch();
  const navigate = useNavigate()
  const [isView, setIsView] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
 
  
  const handleLogin = () => {
    dispatch(LoginUser({ loginData: data }))
      .unwrap()
      .then((res: LoginUserResponse) => {
        
        if (isChecked) {
          localStorage.setItem("email", res.user.email);
          localStorage.setItem("token", res.token);
          localStorage.setItem("name", res.user.name);
        } else {
          sessionStorage.setItem("email", res.user.email);
          sessionStorage.setItem("token", res.token);
          sessionStorage.setItem("name", res.user.name);
        }
        navigate("/main")
      });
  };

  return (
    <div className={style.loginForm}>
      <div className={style.loginInput}>
        <label> Email </label>
        <input
          type="text"
          placeholder="Enter email address"
          name="email"
          value={data.email}
          onChange={onChange}
        />

     
      </div>

      <div className={style.loginInput}>
        <label> Password </label>
        <input
          type={isView ? "text" : "password"}
          placeholder="Enter password"
          name="password"
          value={data.password}
          onChange={onChange}
        />
        <img
          src={view}
          alt="view"
          className={style.viewIcon}
          onClick={() => {
            setIsView((prev) => !prev);
          }}
        />
      </div>

      <div className={style.forgot}>
        <Link to="/forgot"> Forgot password? </Link>

        <div>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => {
              setIsChecked((prev) => !prev);
            }}
          />
          <span> Keep me logged in</span>
        </div>
      </div>

      <div className={style.loginButton}>
        <button onClick={handleLogin}> Login </button>

        <span>
          If you don't have an account, please
          <Link to="/" className={style.signupHref}>
            
            Signup.
          </Link>
        </span>
      </div>
    </div>
  );
};

export default LoginForm;
