import React from 'react'
import style from "./style/verify.module.css"
import EmailHasBeen from './verifypages/EmailHasBeen'
const Verify = () => {
  return (
    <div className={style.verify}>
        <EmailHasBeen />
    </div>
  )
}

export default Verify