import React, { FC, useEffect, useState } from "react";
import style from "../style/createNew.module.css";
import view from "../../images/signup/view.png";
import { usedispatch } from "../../features/store/store";
import { CreateNewPasswords } from "../../features/slice/regLogin/regLoginThunk/regApi";
import { useNavigate,useLocation  } from "react-router-dom";
import { ResponseDateSucess } from "../../globalInterface";
import { dataPassType } from "../newPasswordType";





const CreatePasswordForm: FC = () => {
    const email = localStorage.getItem("email")
    const code =  sessionStorage.getItem("pin")
    const [isView, setIsView] = useState(false)
    const [dataPass, setDataPass] = useState <dataPassType>({
        password:"",
        password_confirmation:"",
        email:email,
        code:code

    })
    
    const {pathname} = useLocation()
    useEffect(()=>{
            if(pathname ==="/create/newpassword"){
                sessionStorage.removeItem("pin")
                localStorage.removeItem("email")
            }
    },[])

    const dispatch = usedispatch()
    const navigate =useNavigate()
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        setDataPass({...dataPass,[e.target.name]:e.target.value})

    }
   
    const handlePass = () =>{
        dispatch(CreateNewPasswords({dataPass})).unwrap().then((res:ResponseDateSucess)=>{
          
            if(res.success){
                navigate("/login")
            }
        })
    }

  return (
    <div className={style.createForm}>
      <div className={style.passInput}>
        <label> New Password</label>
        <input type={isView ? "text" : "password"} name="password" value={dataPass.password} placeholder="Enter new password" onChange={handleChange}/>
        <img src={view} alt="view" className={style.newPassIcon}  onClick={()=>{setIsView(prev=>!prev)}}/>
      </div>

      <div className={style.passInput}>
        <label> New Password</label>
        <input type={isView ? "text" : "password"} name="password_confirmation" value={dataPass.password_confirmation} placeholder="Confirm new password" onChange={handleChange}/>
        <img src={view} alt="view" className={style.confirmPassIcon} onClick={()=>{setIsView(prev=>!prev)}} />
      </div>

      <div className={style.loginButton}>
        <button onClick={handlePass}> Login </button>
      </div>
    </div>
  );
};

export default CreatePasswordForm;
