import React from "react";
import style from "../style/compression.module.css";
import { compressionFormType } from "../compressionType";
const CompressionForm = ({ url, setUrl,isChecked, handleChangeCheckbox,handleCreateCompression}: compressionFormType) => {
  return (
    <div className={style.compressionForm}>
      <div className={style.compressionInput}>
        <span> URL (required)</span>
        <input
          type="text"
          placeholder="URL"
          value={url}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setUrl(e.target.value);
          }}
        />
      </div>

      <div className={style.checkboxInput}>
        <div>
          <input type="checkbox" name="html" checked={isChecked.html}onChange={handleChangeCheckbox}/> <span> HTML </span>
        </div>

        <div>
          <input type="checkbox" name="css" checked={isChecked.css} onChange={handleChangeCheckbox}/> <span> CSS </span>
        </div>

        <div>
          <input type="checkbox" name="js" checked={isChecked.js} onChange={handleChangeCheckbox}/> <span> JS </span>
        </div>
      </div>

      <div className={style.saveButtons}>
        <button onClick={handleCreateCompression}> Save </button>
      </div>
    </div>
  );
};

export default CompressionForm;
