import { createSlice } from '@reduxjs/toolkit'
import { LoginUser } from './regLoginThunk/regApi'
import { regLoginSliceType } from '../../types/loginApi'
import { RootState } from '../../store/store'



const regLoginSlice = createSlice({
    name:"reg/login",
    initialState:{
       isAuth:  sessionStorage.getItem("token") ||localStorage.getItem("token")
    },
    reducers:{
        logout:(state:regLoginSliceType) =>{
            
           localStorage.clear()
           sessionStorage.clear()
            state.isAuth = false;
        }
    },
    extraReducers(builder) {
        builder.addCase(LoginUser.fulfilled,(state:regLoginSliceType,action)=>{
            state.isAuth = true
        })
     
    },

})
export const selectAuthIn = (state:RootState) => state.users.isAuth
export const {logout} = regLoginSlice.actions
export const regLoginSliceReducer = regLoginSlice.reducer