import React, { FC } from 'react'
import style from "../style/createNew.module.css"
const CreateNewPasswordText:FC = () => {
  return (
    <div className={style.createPassText}>
        <span>Create new password </span>
        <p>Please enter your new password </p>
    </div>
  )
}

export default CreateNewPasswordText