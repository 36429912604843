import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import ReparoMenuTitle from "./reparoPages/ReparoMenuTitle";
import style from "./style/reparo.module.css";
import { RootState, usedispatch } from "../../features/store/store";
import { logout } from "../../features/slice/regLogin/regLoginSlice";
import { GetAllDomain } from "../../features/slice/reparo/reparoThunk";
import { useSelector } from "react-redux";
import { token } from "../../../assets/globalState";

const ReaparoMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { allDomain } = useSelector((state: RootState) => state.reparo);
  

 
  const navigate = useNavigate();
  const dispatch = usedispatch();
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleExit = () => {
    dispatch(logout());
    navigate("/login");
  };
  useEffect(() => {
    dispatch(GetAllDomain(token));
  }, [dispatch]);
  return (
    <div className={style.reparoMenu}>
      <ReparoMenuTitle
        handleExit={handleExit}
        isOpen={isOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        allDomain={allDomain}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default ReaparoMenu;
