import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createImageType,
  deleteImage,
  downloadImages,
  getAllImageUserType,

} from "../imageTypes";
import { deleteImages, getImage } from "../imageSlice";


export const CreateImage = createAsyncThunk(
  "image/create",
  async ({ images, url, token, domain_id }: createImageType,{dispatch}) => {
    var formdata = new FormData();
    formdata.append("url", url);
    formdata.append("domain_id", domain_id);
    for (let i = 0; i < images.length; i++) {
      formdata.append("images[]", images[i]);
    }

    const requestOptions = {
      method: "POST",
      headers: { Authorization: token },
      body: formdata,
    };

    const response = await fetch(
      "https://5756.freelancedeveloper.site/api/image/enter/link",
      requestOptions
    );

    const data = await response.json();
     dispatch(getImage(data.data))
   
  }
);

export const GetAllImageUser = createAsyncThunk(
  "image/get",
  async ({ domain_id, token }: getAllImageUserType) => {
    const requestOptions = {
      method: "GET",
      headers: { Authorization: token },
    };
  
    const response =  await fetch(
        `https://5756.freelancedeveloper.site/api/image/all?domain_id=${domain_id}`,
        requestOptions
      );
      const data =  await response.json();
      
   
      return data.data
  }
);


export const Download = createAsyncThunk(
  "image/download",
  async ({ ids, token }: downloadImages) => {
    const requestOptions = {
      method: "GET",
      headers: { Authorization: token },
      responseType: 'blob',
    };

    const response = await fetch(
      `https://5756.freelancedeveloper.site/api/image/download?ids=[${ids.toString()}]`,
      requestOptions
    )
    return response;

  }
);



export const DeleteImageWithId = createAsyncThunk(
  "delete",
  async(data:deleteImage,{dispatch})=>{

    const token = data.token
    const id = data.selected
  
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: token },
    };
 
 
    await fetch(`https://5756.freelancedeveloper.site/api/image/delete?ids=[${id}]`, requestOptions)
  
    dispatch(deleteImages(id))
  }
)