import React, { FC, useState } from "react";
import style from "../style/signUp.module.css";
import view from "../../images/signup/view.png";
import { Link, useNavigate } from "react-router-dom";
import { CreateUser } from "../../features/slice/regLogin/regLoginThunk/regApi";
import { usedispatch } from "../../features/store/store";
import { SignUpDto } from "../../features/types/regApi";

const SignUpForm: FC = () => {
  const [isView, setIsView] = useState(false);
  const [signUp, setSignUp] = useState<SignUpDto>({
    name: "",
    phone: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
const navigate = useNavigate()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignUp({ ...signUp, [e.target.name]: e.target.value });
  };
  const dispatch = usedispatch();
  const handleCreate = () => {
    if (signUp.password === signUp.password_confirmation) {
      dispatch(CreateUser(signUp)).unwrap().then((res)=>{
        if(res.success){
          navigate("/login")
        }
       
      })
    }
  };

  return (
    <div className={style.signUpForm}>
      <div>
        <label> Username </label>
        <div className={style.formInput}>
          <input
            type="text"
            name="name"
            value={signUp.name}
            placeholder="Enter your name"
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <label> Phone </label>
        <div className={style.formInput}>
          <input
            type="text"
            name="phone"
            value={signUp.phone}
            placeholder="Enter your phone"
            onChange={handleChange}
          />
        </div>
      </div>

      <div>
        <label> Email </label>
        <div className={style.formInput}>
          <input
            type="text"
            name="email"
            value={signUp.email}
            placeholder="Enter email address"
            onChange={handleChange}
          />
        </div>
      </div>

      <div>
        <label className={style.passwordLabel}> Password </label>
        <div className={style.formInputIcon}>
          <input
            type={isView ? "text" : "password"}
            name="password"
            value={signUp.password}
            placeholder="Enter password"
            onChange={handleChange}
          />
          <img
            src={view}
            alt="view"
            className={style.viewIcon}
            onClick={() => {
              setIsView((prev) => !prev);
            }}
          />
        </div>
      </div>
      <div>
        <label className={style.passwordLabel}> Confirm password </label>
        <div className={style.formInputIcon}>
          <input
            type={isView ? "text" : "password"}
            name="password_confirmation"
            value={signUp.password_confirmation}
            placeholder="Confirm password"
            onChange={handleChange}
          />
          <img
            src={view}
            alt="view"
            className={style.viewIcon}
            onClick={() => {
              setIsView((prev) => !prev);
            }}
          />
        </div>
      </div>
      <div className={style.signUpButton}>
        <button onClick={handleCreate}> Sign up</button>

        <span>
      
          if you already have an account, please
          <Link to="/login" className={style.loginHref}>
            Login
          </Link>
        </span>
      </div>
    </div>
  );
};

export default SignUpForm;
