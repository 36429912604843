import { createAsyncThunk } from "@reduxjs/toolkit";
import { createCompressionUrlType, deleteCompressionType, editCompressionType, getAllComporessionType } from "../compressionType";
import { createCompression, deleteAllCompression } from "../compressionSlice";
import { domain_id, token } from "../../../../../assets/globalState";

export const CreateCompressionUrl = createAsyncThunk(
  "create/compression",
  async ({
    html,
    js,
    css,
    domain_id,
    url,
    token,
  }: createCompressionUrlType,{dispatch}) => {
    const formdata: any = new FormData();
    formdata.append("domain_id", domain_id);
    formdata.append("html", html);
    formdata.append("js", js);
    formdata.append("css", css);
    formdata.append("url", url);
    const requestOptions = {
      method: "POST",
      headers: { Authorization: token },
      body: formdata,
    };
  const  reponse = await fetch("https://5756.freelancedeveloper.site/api/rule/page/compression/create",requestOptions)
  const data  = await reponse.json()
       dispatch(createCompression(data.data))


       return data.data
  }



);


export const GetAllCompression =createAsyncThunk(
  "get/compression",
  async({token,domain_id}:getAllComporessionType)=>{
    const requestOptions = {
      method: "GET",
      headers: { Authorization: token },
    
    };
    const response = await fetch(`https://5756.freelancedeveloper.site/api/rule/page/compression/all?domain_id=${domain_id}`, requestOptions)
    const data = await response.json()
  
    return data.data
  }
)


export const DeleteCompression = createAsyncThunk(
  "delete/compression",
  async({domain_id,id,token}:deleteCompressionType,{dispatch})=>{

    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: token },
    
    };
   
    const response = await fetch(`https://5756.freelancedeveloper.site/api/rule/page/compression/delete?domain_id=${domain_id}&page_id=${id}`, requestOptions)
   await response.json()
   dispatch(deleteAllCompression())
   
  }
)


export const EditCompression = createAsyncThunk(
  "edit/compression",
  async({token,domain_id,id}:any)=>{
    const requestOptions = {
      method: "GET",
      headers: { Authorization: token },
    
    };

    const response = await fetch(`https://5756.freelancedeveloper.site/api/rule/page/compression/details?domain_id=${domain_id}&page_id=${id}`, requestOptions)

   const data =  await response.json()
  return data.data
  }
)


export const SencEditCompression = createAsyncThunk(
  "send/edit",
  async({url,id,css,js,html}:any)=>{
    const formdata: any = new FormData();
    formdata.append("domain_id", domain_id);
    formdata.append("html", html);
    formdata.append("js", js);
    formdata.append("css", css);
    formdata.append("url", url);
    formdata.append("page_id", id);
    formdata.append("status", "disabled");
    const requestOptions = {
      method: "POST",
      headers: { Authorization: token },
      body: formdata,
    };
 const response =   await fetch("https://5756.freelancedeveloper.site/api/rule/page/compression/update", requestOptions)
    const data = await response.json()
   
    return data.data
   
  }
)


