import React, { useState } from "react";
import style from "../style/pageImage.module.css";
import { usedispatch } from "../../../../component/features/store/store";
import { CreateImage } from "../../../../component/features/slice/image/imageThunk";
import { domain_id, token } from "../../../globalState";



const PageUrlTitle = () => {
  const [images, setImage] = useState([]);
  const [url, setUrl] = useState<string>('');



  const dispatch = usedispatch();

  const handleImage = () => {
    dispatch(CreateImage({ token, url, images,domain_id }));
  };
  return (
    <div className={style.pageUrlTitle}>
      <span>Please enter here image URL </span>
      <div>
        <input
          type="text"
          placeholder="URL address..."
          className={style.inputUrl}
          value={url}
          onChange={({ target }) => setUrl(target.value)}
        />
      </div>

      
      <div className={style.converContainer}>
      <button className={style.convert} onClick={handleImage}>  Convert to base 64 </button>
      </div>
    </div>
  );
};

export default PageUrlTitle;
