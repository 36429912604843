import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Settings from "./assets/pages/settings";

import Main from "./component/dashboard/main";

import { useSelector } from "react-redux";

import { selectAuthIn } from "./component/features/slice/regLogin/regLoginSlice";

import { privateRoute, publicRoute } from "./assets/routes";

function App() {
  const isAuth = useSelector(selectAuthIn);

  return (
    <BrowserRouter>
      <Routes>
        {isAuth && (
          <Route>
            <Route path="/main" element={<Main />}>
              <Route path="settings" element={<Settings />}>
                {privateRoute &&
                  privateRoute.map(({ path, Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                  ))}
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="/main" />} />
          </Route>
        )}

        {!isAuth && (
          <Route>
            {publicRoute.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}

            <Route path="*" element={<Navigate to="/login" />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
