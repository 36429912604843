import React from 'react'
import style from "../style/cachingRule.module.css"


const CachingRuleHeader = () => {

  return (
    <div className={style.cachingRuleHeader}>
        <div className={style.position}>
            <span> Position</span>

        </div>
        <div className={style.urlDescription}>
            <span> URL/Description</span>

        </div>

        <div className={style.action}>
            <span> Action</span>

        </div>

    </div>
  )
}

export default CachingRuleHeader