import React, { FC } from "react";
import style from "../style/forgot.module.css";
import email from "../../images/signup/email.png"
const ForgotText: FC = () => {
  return (
    <div className={style.forgotText}>
      <span>Forgot password? </span>
      <p>
        Enter your registered email below to receive password reset instruction
      </p>
        <img src={email} alt="email" />
    </div>
  );
};

export default ForgotText;
