export const  settingsPagination = [
    {   
        
        name:"Caching",
        link:"caching",
    },

    {
        name:"Images",
        link:"page/image"
    },

    {
        name:"Compression",
        link:"page/compression"
    },



    {
        name:"Third-party",
        link:"third/part"
    },

    {
        name:"Speed index",
        link:"speed/index"
    },

   
]