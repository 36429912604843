import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createRuleType,
  deleteUlrType,

  editRuleType,
  getAllRulePageType,
} from "../reparo/reparoTypes";
import { createRule, deleteRuleURL } from "./cachingSlice";
import { editSendRuleTYpe } from "./cachingInterface";
import { token } from "../../../../assets/globalState";

export const GetCacheBrowser = createAsyncThunk(
  "get/cach",
  async (token: string) => {
    const requestOptions = {
      method: "GET",
      headers: { Authorization: token },
    };

    const response = await fetch(
      "https://5756.freelancedeveloper.site/api/rule/page/select/list",
      requestOptions
    );
    const data = await response.json();

    return data.data;
  }
);

export const CreateRule = createAsyncThunk(
  "create",
  async ({ newObj, token }: createRuleType, { dispatch }) => {
    const formdata = new FormData();
    formdata.append("domain_id", String(newObj.domain_id));
    formdata.append("url", newObj.url);
    formdata.append("browser_cache_ttl", newObj.browser_cache_ttl);
    formdata.append("cache_level", newObj.cache_level);
    formdata.append("edge_cache_ttl", newObj.edge_cache_ttl);
    formdata.append("explicit_cache_control", newObj.explicit_cache_control);
    formdata.append("status", newObj.status);
    const requestOptions = {
      method: "POST",
      headers: { Authorization: token },
      body: formdata,
    };

    const response = await fetch(
      "https://5756.freelancedeveloper.site/api/rule/page/create",
      requestOptions
    );
    const data = await response.json();
    dispatch(createRule(data.data));
  }
);

export const GetAllRulePage = createAsyncThunk(
  "get/rule",
  async ({ domain_id, token }: getAllRulePageType) => {
    const requestOptions = {
      method: "GET",
      headers: { Authorization: token },
    };

    const response = await fetch(
      `https://5756.freelancedeveloper.site/api/rule/page/all?domain_id=${domain_id}`,
      requestOptions
    );
    const responseData = await response.json();

    return responseData.data;
  }
);

export const EditRule = createAsyncThunk(
  "edit/role",
  async ({ domain_id, token,page_id }: editRuleType) => {
    const requestOptions = {
      method: "GET",
      headers: { Authorization: token },
    };

    const response = await fetch(
      `https://5756.freelancedeveloper.site/api/rule/page/details?domain_id=${domain_id}&page_id=${page_id}`,
      requestOptions
    );
    const data = await response.json()
  
    return data.data
  }
);

export const DeleteUlr = createAsyncThunk(
  "delete/url",
  async ({ id, domain_id, token }: deleteUlrType, { dispatch }) => {
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: token },
    };

    await fetch(
      `https://5756.freelancedeveloper.site/api/rule/page/delete?domain_id=${domain_id}&page_id=${id}`,
      requestOptions
    );

    dispatch(deleteRuleURL(id));
  }
);



export const EditSendRule = createAsyncThunk(
  "edit/send/rule",
  async (data: any, { dispatch }) => {
    var formdata = new FormData();
    formdata.append("domain_id", data.domain_id);
    formdata.append("url", data.url);
    formdata.append("browser_cache_ttl", data.browser_cache_ttl);
    formdata.append("cache_level", data.cache_level);
    formdata.append("edge_cache_ttl", data.edge_cache_ttl);
    formdata.append("explicit_cache_control", data.explicit_cache_control);
    formdata.append("page_id", data.page_id);
    formdata.append("status", data.status);

    const requestOptions = {
      method: "POST",
      headers: { Authorization: token },
      body: formdata,
    };
    const response = await fetch("https://5756.freelancedeveloper.site/api/rule/page/update", requestOptions)
    const responseData = await response.json()
    return responseData.data
  }
)