import { createSlice } from "@reduxjs/toolkit";
import { CreateCompressionUrl, EditCompression, GetAllCompression, SencEditCompression } from "./compressionThunk";



const initialState= {
    isLoading:false,
    allCompression:[],
    editCompression:[],
    isLoadingAdd:false,
    isLoadingEdit:false

   
}
const compressionSlice = createSlice({
                name:"compression",
                initialState,
                reducers:{
                        createCompression:(state:any,action)=>{
                            if(typeof action.payload !== 'string') {
                                state.allCompression= [action.payload]
                            }
                        },
                        deleteAllCompression:(state)=>{
                            state.allCompression=[]
                        }
                        
                },
                extraReducers:(bulider)=>{
                    bulider.addCase(GetAllCompression.pending,(state)=>{
                        state.isLoading = true
                    })
                    .addCase(GetAllCompression.fulfilled,(state,action)=>{
                        state.isLoading = false
                      
                        if(action.payload) {
                         
                            state.allCompression = action.payload
                        }
                    })
                    .addCase(CreateCompressionUrl.pending,(state)=>{
                        state.isLoadingAdd = true
                    })
                    .addCase(CreateCompressionUrl.fulfilled,(state)=>{
                        state.isLoadingAdd = false
                    })
                .addCase(EditCompression.fulfilled,(state:any,action)=>{
                      state.editCompression = [action.payload]
                })
                .addCase(SencEditCompression.pending,(state)=>{
                    state.isLoadingEdit = true
                })
                .addCase(SencEditCompression.fulfilled,(state:any,action)=>{
                    state.isLoadingEdit = false
                    if(action.payload){
                        state.allCompression = [{...action.payload}]
                    }
                  
                })
                
                }
})
export const {createCompression,deleteAllCompression} =compressionSlice.actions
export const compressionSliceReducer = compressionSlice.reducer