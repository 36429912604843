import React, { useState } from 'react'
import { compressionModalType, isCheckedType } from '../compressionType'
import style from "../style/compression.module.css"
import { usedispatch } from '../../../../component/features/store/store'
import {GetAllCompression, SencEditCompression } from '../../../../component/features/slice/compression/compressionThunk'
import {token,domain_id} from "../../../globalState/index"
const CompressionModal = ({setOpen}:compressionModalType) => {

  const id = localStorage.getItem("pageCompressionId")
  const dispatch = usedispatch()



const [url, setUrl] = useState("")


const [isChecked, setIsChecked]= useState <isCheckedType>({
  html:false,
  css:false,
  js:false
})
const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>)=>{
  setIsChecked({...isChecked,[e.target.name]:e.target.checked})
}

const handleEditCompression = () =>{
  const html = isChecked.html ? "on" :"off"
  const js = isChecked.js ? "on" :"off"
  const css = isChecked.css ? "on" :"off"
  dispatch( SencEditCompression({url,id,css,js,html}))
  setOpen(false)
}

  return (
    <div className={style.compressionModal}>

      <div className={style.modalInput}>

      <span className={style.span}> URL (required) </span>
      <input type="text" placeholder='URL'  value={url} onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setUrl(e.target.value)} />
      </div>


      <div className={style.inputCheckbox}>
        <div> <input type='checkbox' name='html' checked={isChecked.html} onChange={handleChangeCheckbox}/> <span className={style.span} > HTML</span>  </div>
        <div> <input type='checkbox' name='css' checked={isChecked.css} onChange={handleChangeCheckbox}/> <span className={style.span}> CSS </span>  </div>
        <div> <input type='checkbox' name='js' checked={isChecked.js}  onChange={handleChangeCheckbox} /> <span className={style.span}> JS</span>  </div>
      </div>



     <div className={style.save}>
        <button className={style.saveButton} onClick={handleEditCompression}> Save </button>
        <button className={style.cancelButton} onClick={()=>setOpen(false)}> Cancel </button>
     </div>


    </div>
  )
}

export default CompressionModal