import React, {  useState } from "react";
import style from "../style/caching.module.css";
import Switch from "@mui/material/Switch";
import { BrowserCatchType } from "../../../settingstypes";
import {  usedispatch } from "../../../../../../component/features/store/store";
import { CreateRule } from "../../../../../../component/features/slice/caching/cachingApi";
import { token } from "../../../../../globalState";



interface cacheDataType {
  domain_id:number
  url: string;
  browser_cache_ttl: string;
  cache_level: string;
  edge_cache_ttl:string,

  status:string
}

const CachingSelect = ({ browserCache, edge, cachLevel }: BrowserCatchType) => {


  const id = Number(localStorage.getItem("id")) 
  
  const [loading, setLoading] = React.useState(true);
  const [catchData, setCatchData] = useState<cacheDataType>({
    domain_id: id,
    url: "",
    browser_cache_ttl: "",
    cache_level: "",
    edge_cache_ttl:"",

    status:"disabled"
  });

 


  


  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCatchData({ ...catchData, [e.target.name]: e.target.value });
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setCatchData({ ...catchData, [e.target.name]: e.target.value,});
  };
  const dispatch = usedispatch()
  const handleCreatePage = () => {
    if(catchData){
      
    }
    const explicit_cache_control = loading ? "on" : "off"
    const newObj = {...catchData,explicit_cache_control}
    dispatch(CreateRule({newObj,token}))
  }


  return (
    <div className={style.cachingSelect}>
      <div className={style.enter}>
        <span>URL (requried) </span>
        <input
          type="text"
          placeholder="URL"
          name="url"
          className={style.url}
          value={catchData.url}
          onChange={handleChangeInput}
        />
      </div>

      <div className={style.enter}>
        <span>Enter Browser Cache TTL (requried) </span>
        <select
          name="browser_cache_ttl"
          value={catchData.browser_cache_ttl}
          onChange={handleChangeSelect}
        >
        
          {browserCache.map((item) => (
            <option key={item.id} value={item.value}>{item.name}</option>
          
          ))}
        </select>
      </div>

      <div className={style.enter}>
        <span>Select Cache Level (required) </span>

        <select
          name="cache_level"
          value={catchData.cache_level}
          onChange={handleChangeSelect}
        >
          {cachLevel.map((item) => (
            <option key={item.id} value={item.value}>{item.name}</option>
          ))}
        </select>
      </div>

      <div className={style.enter}>
        <span>Enter Edge Cache TTL (required) </span>
        <select name="edge_cache_ttl"  value={catchData.edge_cache_ttl} onChange={handleChangeSelect}>
          {edge.map((item) => (
            <option key={item.id} value={item.value}>{item.name}</option>
          ))}
        </select>
      </div>

      <div className={style.switchButton}>
        <Switch
          checked={loading}
          onChange={() => setLoading(!loading)}
          name="loading"
          color="primary"
        />
        <span> Origin Cache Control</span>
      </div>
          
      <div className={style.purgeCreateButtons}>
        <button className={style.purge}> Purge All Cache</button>
        <button className={style.rule} onClick={handleCreatePage}> Create page Rule </button>
      </div>
      
    </div>
  );
};

export default CachingSelect;
