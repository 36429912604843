import React, { FC } from 'react'
import CreateNewPasswordText from './createPasswordpages/CreateNewPasswordText'
import CreatePasswordForm from './createPasswordpages/CreatePasswordForm'
import style from "./style/createNew.module.css"
const CreateNewPassword:FC = () => {
  return (
    <div className={style.createNewPassword}>
        <CreateNewPasswordText />
        <CreatePasswordForm />
    </div>
  )
}

export default CreateNewPassword