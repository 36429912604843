import React, { useEffect } from "react";
import style from "./style/cachingRule.module.css";
import CachingRuleHeader from "./cachingRulePages/CachingRuleHeader";
import {
  RootState,
  usedispatch,
} from "../../../../../../component/features/store/store";
import { GetAllRulePage } from "../../../../../../component/features/slice/caching/cachingApi";
import CachingRuleTables from "./cachingRulePages/CachingRuleTables";
import { useSelector } from "react-redux";
import { token } from "../../../../../globalState";
const CachginRule = () => {
  const domain_id: number = Number(localStorage.getItem("id"));
  const { isLoading } = useSelector((state: RootState) => state.caching);

  const dispatch = usedispatch();

  useEffect(() => {
    dispatch(GetAllRulePage({ domain_id, token }));
  }, []);

  return (
    <div className={style.cachingRule}>
      <CachingRuleHeader />
      {isLoading ? <span> Loading ...</span> : <CachingRuleTables />}
     
    </div>
  );
};

export default CachginRule;
