import { createSlice, current } from "@reduxjs/toolkit";
import {  initialStateType } from "./imageTypes";
import { CreateImage, DeleteImageWithId, Download, GetAllImageUser } from "./imageThunk";

const initialState: initialStateType = {
  getImageUser: [],
  isLoading: false,
  isLoadintDeleteImage:false,
  isLoadingAddImage:false,
  isLoadingDownload:false
};
const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    getImage: (state: any, action) => {
      state.getImageUser.push(...action.payload);
    },
    deleteImages: (state: any, action) => {
   
       
        state.getImageUser = state.getImageUser.filter(({id}:any)=>{
           return !action.payload.includes(id)
        })
  
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllImageUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllImageUser.fulfilled, (state: initialStateType, action) => {
        state.isLoading = false;
        state.getImageUser = action.payload;
       
      })
      .addCase(Download.pending,(state)=>{
        state.isLoadingDownload = true
      })
      .addCase(Download.fulfilled, (state, action) => {
        const response = action.payload;
        state.isLoadingDownload = false
        response.blob().then((blob: any) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "images-user.zip";

          a.click();
        });
     
      })    
      
      
      .addCase(DeleteImageWithId.pending,(state)=>{
        state.isLoadingDownload = true
        
    })

    .addCase(DeleteImageWithId.fulfilled,(state)=>{
        state.isLoadintDeleteImage = false
    })
    .addCase(CreateImage.pending,(state)=>{
          state.isLoadingAddImage=true
    })
    .addCase(CreateImage.fulfilled,(state)=>{
      state.isLoadingAddImage =false
    })
 
   
  },
});
export const { getImage, deleteImages } = imageSlice.actions;
export const imageSliceReducer = imageSlice.reducer;




