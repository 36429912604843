import React from "react";
import style from "../style/reparo.module.css";
import Box from "@mui/material/Box";
import rocket from "../../../images/reparomenu/rocket.svg";
import Modal from "@mui/material/Modal";
import plus from "../../../images/reparomenu/plus.svg";
import logo from "../../../images/reparomenu/logo.svg";
import exit from "../../../images/reparomenu/exit.svg";
import { Link } from "react-router-dom";
import { addModalStyle } from "./addDomanModal/style/addModalStyle";
import AddDomainMoal from "./addDomanModal/AddDomainMoal";
import { ReparoMenuProps, allDomainType } from "../reparoType";






const ReparoMenuTitle = ({
  handleExit,
  handleClose,
  handleOpen,
  isOpen,
  allDomain,
  setIsOpen
}: ReparoMenuProps) => {

  const handleGetId = (id:string) => {
        localStorage.setItem("id",id)
  }

  return (
    <div className={style.reparoTitle}>
      <img src={logo} alt="logo" />
      <button>
        
        <img src={plus} alt="plus" />
        Add Subdirectory
      </button>
      <button onClick={handleOpen}>
        <img src={plus} alt="plus" /> Add Subdomain
      </button>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...addModalStyle}}>
          <AddDomainMoal handleClose={handleClose}  setIsOpen={setIsOpen}/>
        </Box>
      </Modal>

      <div className={style.webLink}>
    
        {
         allDomain && allDomain.map((item:allDomainType)=>(
            <div key={item.id}>
                <div className={style.webPageination}>
                <img src={rocket} alt="rocket" />
                <Link to="settings" onClick={()=>handleGetId(item.id)}>{item.name} </Link>
                
                  </div>
              </div>
          ))
        }
      </div>

      <div className={style.exit}>
        <div className={style.buttonExit}>
          <img src={logo} alt="logo" />
          <img src={exit} alt="exit" onClick={handleExit} />
        </div>
      </div>
    </div>
  );
};

export default ReparoMenuTitle;
