import React, { FC } from "react";
import style from "../style/verify.module.css";
import email from "../../images/signup/email.png";
import { Link } from "react-router-dom";

import { ResendEmail } from "../../features/slice/regLogin/regLoginThunk/regApi";
import { usedispatch } from "../../features/store/store";

const EmailHasBeen:FC = () => {
  const emails:string | null = localStorage.getItem("email")
 const dispatch = usedispatch()
  const handleResend = () => {
    dispatch(ResendEmail(emails))
  }
  return (
    <div className={style.emailHasBeen}>
      <span>Email has been sent! </span>
      <p>
        Please check your inbox and click in the received email to reset a
        password
      </p>
        <div className={style.emailVerify}>
        <img src={email} alt="email" />
        <button> <Link to="/check/email">Verify email  </Link>  </button>
        <div>
        <span> Didn't receive the email?    </span>
        <button className={style.resend} onClick={handleResend}> Resend. </button>
        </div>
        
        </div>
      


    </div>
  );
};

export default EmailHasBeen;
