import { createSlice } from '@reduxjs/toolkit'
import {  GetAllDomain } from './reparoThunk'

const initialState = {
    allDomain:[],
   
}

const reparoSlice = createSlice({
    name:"reparo",
    initialState,
    reducers:{
    creatDomainAdd:(state:any,action)=>{
        
        state.allDomain.push({...action.payload})
    }

    },
    extraReducers:(bulider)=>{
      bulider.addCase(GetAllDomain.fulfilled,(state,action)=>{
        state.allDomain = action.payload
      })
       
    }
})

export const {creatDomainAdd} = reparoSlice.actions
export const reparoSliceReducer = reparoSlice.reducer