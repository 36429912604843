import React from 'react'
import SignUpForm from './signUpPages/SignUpForm'
import SignUpText from './signUpPages/SignUpText'
import style from "./style/signUp.module.css"
const SignUp:React.FC = () => {
  return (
    <div className={style.signUp} >
        <SignUpText />
        <SignUpForm />
    </div>
  )
}

export default SignUp