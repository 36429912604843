import React, { useState } from "react";
import style from "./style/addModal.module.css";
import { usedispatch } from "../../../../features/store/store";
import { CreateDomanApi } from "../../../../features/slice/reparo/reparoThunk";
import { token } from "../../../../../assets/globalState";



interface addDomainModaType{
  handleClose:()=>void,
  setIsOpen:(isOpen:boolean)=>void
}

const AddDomainMoal = ({handleClose,setIsOpen}:addDomainModaType) => {
  const [domain, setDomain] = useState("");


  const dispatch  = usedispatch()
 const createDoman = () => {
    dispatch(CreateDomanApi({domain,token}))
    setIsOpen(false)
 }
  return (
    <div className={style.domainContainer}>
      <h2> Create Domain</h2>

      <div className={style.domainInput}>
        <span> Domain</span>
        <input
          type="text"
          placeholder="Domain"
          value={domain}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDomain(e.target.value)
          }
        />
      </div>
      <div className={style.domainButtons}>
      <button className={style.create} onClick={createDoman}> Create</button>
      <button  className={style.cancel} onClick={handleClose}>  Cancel </button>
      </div>
    
    </div>
  );
};

export default AddDomainMoal;
