import React from 'react'
import CheckEmailText from './checkEmailPages/CheckEmailText'
import style from "./style/checkEmail.module.css"
const CheckEmail = () => {
  return (
    <div className={style.checkmail}>

        <CheckEmailText />
    </div>
  )
}

export default CheckEmail