import React, { FC } from 'react'
import style from "../style/login.module.css"
const LoginText:FC = () => {
  return (
    <div className={style.loginText}>
        <span> Login</span>
        <p> Welcome back! <br/>Please enter your information </p>
    </div>
  )
}

export default LoginText