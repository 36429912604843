import React from "react";
import style from "../style/settings.module.css";
const SettingsTitle = () => {
  return (
    <div className={style.settingsTitle}>
      <span> Settings</span>
      <button> Save Settings</button>
    </div>
  );
};

export default SettingsTitle;
