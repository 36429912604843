import React, { FC, useState } from 'react'
import { ForgotPassword } from '../../features/slice/regLogin/regLoginThunk/regApi';
import { usedispatch } from '../../features/store/store';
import { useNavigate } from "react-router-dom";
import style from "../style/forgot.module.css";
import { ResponseDateSucess } from '../../globalInterface';

const ForgotForm:FC = () => {
  const [email,setEmail] = useState("")
  const dispatch = usedispatch()
  const navigate = useNavigate()
const handleForgotPassword = () => {
  dispatch(ForgotPassword(email)).unwrap().then((res:ResponseDateSucess)=>{
   
    if(res.success){
      localStorage.setItem("email",email)
      navigate("/verify")

    }
  })
}
  return (
    <div className={style.forgotForm}>
      <div className={style.forgotInput}>
        <label> Email </label>
        <input type="email" value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setEmail(e.target.value)}/>
      </div>

      <button onClick={handleForgotPassword}> Send</button>
    </div>  
  )
}

export default ForgotForm