
import CheckEmail from "../../component/checkEmail";
import CreateNewPassword from "../../component/createNewpassword";
import Forgot from "../../component/forgotpassword";
import Login from "../../component/login";
import SignUp from "../../component/signUp/SignUp";
import Verify from "../../component/verify";
import Compression from "../pages/compression";
import PageImage from "../pages/pageImage";

import Caching from "../pages/settings/settingsPagination/catching";

export const privateRoute =[
   


    {
        path:"caching",
        Component: Caching
    },

    {
        path:"page/image",
        Component: PageImage
    },
    {
        path:"page/compression",
        Component: Compression
    },

]


export const publicRoute = [
    {
        path:"/",
        Component: SignUp
    },

    {
        path:"/login",
        Component: Login
    },

    {
        path:"/forgot",
        Component: Forgot
    },

    {
        path:"/verify",
        Component: Verify
    },

    {
        path:"/check/email",
        Component: CheckEmail
    },

    {
        path:"/create/newpassword",
        Component: CreateNewPassword
    },
]