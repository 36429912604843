import React, { useEffect } from 'react'
import style from "./style/pageImage.module.css"
import PageUrlTitle from './page/PageUrlTitle'
import {  RootState, usedispatch } from '../../../component/features/store/store'
import { GetAllImageUser } from '../../../component/features/slice/image/imageThunk'
import PageImageTable from './page/PageImageTable'
import { useSelector } from 'react-redux'


const PageImage = () => {
  const {isLoading} = useSelector((state:RootState)=>state.image)
 
  const token = `Bearer ${(sessionStorage.getItem("token") || localStorage.getItem("token"))}`
  const domain_id:string | null = localStorage.getItem("id")
  const dispatch = usedispatch()
 useEffect(()=>{
  dispatch(GetAllImageUser({domain_id,token}))
 },[])

  return (
    <>
       <div className={style.pageImage}>
     <PageUrlTitle />
     
    </div>
    <div className={style.imageTable}>
      {
        isLoading ? <span> Loading...  </span> :    <PageImageTable  />
      }

    </div>
  
    </>
 
  )
}

export default PageImage


