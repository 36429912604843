import React, { useEffect, useState } from 'react'
import style from "./style/compression.module.css"
import CompressionForm from './compressionPages/CompressionForm'
import { isCheckedType } from './compressionType'
import { RootState, usedispatch } from '../../../component/features/store/store'
import { CreateCompressionUrl, GetAllCompression } from '../../../component/features/slice/compression/compressionThunk'
import CompressionTable from './compressionPages/CompressionTable'
import { useSelector } from 'react-redux'
const Compression = () => {
  const domain_id:null | string = localStorage.getItem("id")
  const token = `Bearer ${(sessionStorage.getItem("token") || localStorage.getItem("token"))}`
  const {isLoading,isLoadingEdit} = useSelector((state:RootState)=> state.compression)
 
  const dispatch = usedispatch()


  const [url,setUrl] = useState("")
  const [isChecked, setIsChecked]= useState <isCheckedType>({
    html:false,
    css:false,
    js:false
  })
  
  useEffect(()=>{
    dispatch(GetAllCompression({token,domain_id}))
  },[])

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>)=>{
    setIsChecked({...isChecked,[e.target.name]:e.target.checked})
  }

  const handleCreateCompression = ()=>{
    const html = isChecked.html ? "on" : "off"
    const css = isChecked.css ? "on" : "off"
    const js = isChecked.js ? "on" : "off"
    dispatch(CreateCompressionUrl({html,js,css,domain_id,url,token}))
  }
  return (
    <div className={style.compression}>

      <CompressionForm url={url} setUrl={setUrl} isChecked={isChecked} handleChangeCheckbox={handleChangeCheckbox} handleCreateCompression={handleCreateCompression}/>
   {
    isLoading || isLoadingEdit ? <span> Loading...</span> : <CompressionTable />
   }  
    </div>
  )
}

export default Compression


