import React from 'react'
import { Outlet } from 'react-router'
import ReaparoMenu from '../reparoMenu'

import style from "./main.module.css"
const Main = () => {
  return (
    <div className={style.main}>
      <ReaparoMenu />
      <Outlet />
    </div>
  )
}

export default Main