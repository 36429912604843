import React, { ChangeEvent, useEffect, useState } from "react";
import style from "../style/pageImage.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../component/features/store/store";
import {  DeleteImageWithId, Download } from '../../../../component/features/slice/image/imageThunk';
const PageImageTable = () => {
  const dispatch = useDispatch();
  const [isSelectedAll, setIsSselectedAll] = useState<boolean>(false)
  const [selected, setSelected] = React.useState<Array<string>>([])
  const { getImageUser,isLoadintDeleteImage,isLoadingAddImage,isLoadingDownload } = useSelector((state: RootState) => state.image);
  const token = `Bearer ${(sessionStorage.getItem("token") || localStorage.getItem("token"))}`

  const handleSubmit = () => {
    if(isSelectedAll){
      const selected: Array<string> = getImageUser.map(li => li.id) as unknown as Array<string>;
      dispatch(Download({ token, ids: selected }) as any)
    }
    else{
      dispatch(Download({ token, ids: selected }) as any)
    }
  
  }





  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>, id: string) => {
    const { checked } = target  
    if (checked) {
      setSelected([...selected, id])
    } else {
      setSelected(prev => {
        const arr = prev.filter((e: string) => e !== id)
        return arr;
      })
    }
  }
  
  const handleDeleteImage = () => {
    dispatch(DeleteImageWithId({ token, selected }) as any)
  }

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const arr: Array<string> = getImageUser.map(li => li.id) as unknown as Array<string>;
    setIsSselectedAll(!isSelectedAll);
    setSelected(arr);
    if (isSelectedAll) {
      setSelected([]);
    }
  };
  

  return (
    <div className={style.pageImageTable}>
      <div className={style.exportButtons}>
        <button className={style.export} onClick={handleSubmit}> Export selected </button>
        <button className={style.delete} onClick={handleDeleteImage}> Delete selected</button>
      </div>

      <div className={style.informationImage}>
        <div className={style.checkboxHeader}>
          <input type="checkbox" checked={isSelectedAll} onChange={handleSelectAll} />
        </div>

        <div className={style.name}>
          <span className={style.span}> Name</span>
        </div>

        <div className={style.uploadDate}>
          <span className={style.span}> Uploaded date</span>
        </div>
      </div>
      {
        isLoadintDeleteImage || isLoadingAddImage ||isLoadingDownload  ? <span> Loading...</span> :      getImageUser.map((elem: any) => (
          <div key={elem.id} className={style.getData}>
            <div className={style.checkboxTable}>
              <input checked={selected.includes(elem.id)} type="checkbox" onChange={(e) => handleChange(e, elem.id)} />
            </div>
  
            <div className={style.filename}>
              <span className={style.span}> {elem.filename}</span>
            </div>
           
           <div className={style.createdDate}>
              <span className={style.span}> {elem.created_at}</span>
            </div>
            
            
          </div>
        ))
      }

    </div>
  );
};

export default PageImageTable;
