import { createAsyncThunk } from "@reduxjs/toolkit";
import { domainDateType, getDomainType } from "../reparoTypes";
import { creatDomainAdd } from "../reparoSlice";





export const CreateDomanApi  = createAsyncThunk(
    "domain",
    async({token,domain}:domainDateType,{dispatch})=>{
      
        var formdata = new FormData();
        formdata.append("domain", domain) 
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': token },
            body: formdata
            
        };
            
            const response = await fetch('https://5756.freelancedeveloper.site/api/domain/create', requestOptions);
            
            const data = await response.json()
          
          dispatch(creatDomainAdd(data.data))
    

    
    }
)




export const GetAllDomain = createAsyncThunk(
    "get/domen",
    async(token:string)=>{
              
           
        const requestOptions:getDomainType = {
            method: 'GET',
            headers: { 'Authorization': token }
        };
            
            const response = await fetch('https://5756.freelancedeveloper.site/api/domain', requestOptions);

            const data = await response.json()
         
           return data.data

    }
)