import React, {  useState } from "react";
import style from "../style/compression.module.css";
import { useSelector } from "react-redux";
import {
  RootState,
  usedispatch,
} from "../../../../component/features/store/store";
import edit from "../../../images/compression/edit.svg";
import delet from "../../../images/compression/delete.svg";
import Switch from "@mui/material/Switch";
import { DeleteCompression } from "../../../../component/features/slice/compression/compressionThunk";
import { itemDate } from "../compressionType";
import { domain_id, token } from "../../../globalState";
import { Box, Modal } from "@mui/material";
import CompressionModal from "../compressionModal";
import { compressionModal } from "../style/compressionModalStyle";

const CompressionTable = () => {
  const [open, setOpen] = React.useState(false);
  const [checkId, setCheckId] = useState(null);

  const { allCompression, isLoadingAdd } = useSelector(
    (state: RootState) => state.compression
  );
 



  const dispatch = usedispatch();
  const handleDeleteCompression = (id: string) => {
    dispatch(DeleteCompression({ domain_id, id, token }));
  };

  const handleEdit = (id: any) => {
    localStorage.setItem("pageCompressionId", id)
    setCheckId(id);
    setOpen(true);
  };

  return (
    <div className={style.compressionTable}>
      <div className={style.compressionDate}>
        <div className={style.compressionTitle}>
          <div className={style.position}>
            <span className={style.span}> Position </span>
          </div>
          <div className={style.description}>
            <span className={style.span}> URL/Description</span>
          </div>

          <div>
            <span className={style.span}> Action</span>
          </div>
        </div>
      </div>
      {isLoadingAdd ? (
        <span> Loading... </span>
      ) : (
        allCompression &&
        allCompression.map((item: itemDate) => (
          <div key={item.id} className={style.container}>
            <div className={style.position}>
              <span className={style.span}> {item.priority} </span>
            </div>
            {item.targets.map((elem) => (
              <div key={elem.constraint.value} className={style.description}>
                <span className={style.span}> {elem.constraint.value} </span>
              </div>
            ))}

            <div className={style.settings}>
              <Switch name="loading" color="primary" />
              <div className={style.edit}>
                {
                  checkId === item.id &&(

                    <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                     <Box sx={{...compressionModal}}> 

                      <CompressionModal setOpen={setOpen}/>

                     </Box>
                  </Modal>

                  )
                }
              

                <img
                  src={edit}
                  alt="edit"
                  onClick={() => handleEdit(item.id)}
                />
              </div>
              <div className={style.delete}>
                <img
                  src={delet}
                  alt="delet"
                  onClick={() => handleDeleteCompression(item.id)}
                />
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default CompressionTable;
