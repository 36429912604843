import { createAsyncThunk } from "@reduxjs/toolkit";
import { $authHost } from "../../../api";
import { CreateResponsePasswordDate, LoginDate, VerifyPin } from "../../../types/loginApi";
import { SignUpDto } from "../../../types/regApi";


export const CreateUser = createAsyncThunk(
    "createUser",
    async(data: SignUpDto)=>{
        
         const response =   await $authHost.post("/register",data)
           
        return response.data
       
   
    }
)

export const LoginUser = createAsyncThunk(
    "login",
    async({loginData}:LoginDate)=>{
  
        const response = await $authHost.post("/login",loginData)
        
        return response.data

    }
)


export const ForgotPassword = createAsyncThunk(
    "forgot",
    async(email:string)=>{
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        };
        const response = await fetch('https://5756.freelancedeveloper.site/api/forgot/password', requestOptions);
        return await response.json()

      
    }
)


export const ResendEmail = createAsyncThunk(
    "forgot",
    async(email:null |string)=>{
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        };
        const response = await fetch('https://5756.freelancedeveloper.site/api/forgot/password', requestOptions);
        return await response.json()

      
    }
)



export const VerifyCode = createAsyncThunk(
    "verify",
    async(data:VerifyPin)=>{
        
        const response =  await $authHost.post("/verify/pin",data)
        return response.data

    }
)


export const CreateNewPasswords = createAsyncThunk(
    "newpass",
    async({dataPass}:CreateResponsePasswordDate)=>{
        
        const response = await $authHost.post("/reset/password",dataPass)
       return  response.data

    }
)