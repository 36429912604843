export const addModalStyle = {
    position: 'absolute',
    top: '20%',
    left: '20%',
    display:"flex",   
    width: "40%",
    height:"300px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    
}