export const compressionModal = {
    position: "absolute",
   
    left: "15%",
    top:"20%",
    display:"flex",
    flexDirection:"column",
    width: "40%",

    bgcolor: "background.paper",
    border:"none",


  
  };