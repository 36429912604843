import React, { FC, useState } from 'react'
import { LoginDto } from '../features/types/loginApi'
import LoginForm from './loginPages/LoginForm'
import LoginText from './loginPages/LoginText'
import style from "./style/login.module.css"
const Login:FC = () => {
  const [loginData, setLoginData] = useState<LoginDto>({
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  return (
    <div className={style.login}>
      <LoginText />
      <LoginForm data={loginData} onChange={handleChange} />
    </div>
  )
}

export default Login