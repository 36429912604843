import React from 'react'
import ForgotForm from './forgotpages/ForgotForm'
import ForgotText from './forgotpages/ForgotText'
import style from "./style/forgot.module.css"
const Forgot = () => {
  return (
    <div className={style.forgot} >

        <ForgotText />
        <ForgotForm />
    </div>
  )
}

export default Forgot