export const cachingModalStyle = {
    position: "absolute",
   
    left: "15%",
    top:"20%",
    display:"flex",
    flexDirection:"column",
    width: "70%",
    height:"60%",
    bgcolor: "background.paper",

  
  };