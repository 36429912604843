import React from "react";
import style from "../style/edit.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../component/features/store/store";
import {  editSelectType } from "../../cachingType";
import { Switch } from "@mui/material";
import CachingEditButtons from "./CachingEditButtons";
const CachingEditSelect = ({
  isCheck,
  ruleUpdate,
  onChangeInput,
  onChangeSelect,
  setIsCheck,
  handleCancel,
  handleSubmitEdit
}: any) => {
  const { browserCache, cachLevel, edge } = useSelector(
    (state: RootState) => state.caching
  );

const handleChecked = () =>{
  setIsCheck(false)
}


  return (
    <div className={style.cachingEditSelect}>
      <div className={style.containerEdit}>
        <div className={style.inputs}>
          <span className={style.span}> URL (required) </span>
          <input
            type="text"
            name="url"
            placeholder="URL"
            className={style.input}
            value={ruleUpdate.url}
            onChange={onChangeInput}
          />
        </div>

        <div className={style.inputs}>
          <span className={style.span}>
      
            Enter Browser Cache TTL (required)
          </span>
          <select
            className={style.input}
            name="browser_cache_ttl"
            value={ruleUpdate.browser_cache_ttl}
            onChange={onChangeSelect}
          >
            {browserCache &&
              browserCache.map((item: editSelectType) => (
                <option key={item.id} value={item.value}>
               
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <div className={style.inputs}>
          <span className={style.span}> Select Cache Level (required)</span>
          <select
            name="cache_level"
            className={style.input}
            value={ruleUpdate.cache_level}
            onChange={onChangeSelect}
          >
            {cachLevel &&
              cachLevel.map((item: editSelectType) => (
                <option key={item.id} value={item.value}>
                  {" "}
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <div className={style.inputs}>
          <span className={style.span}> Enter Edge Cache TTL(required)</span>
          <select
            name="edge_cache_ttl"
            className={style.input}
            value={ruleUpdate.edge_cache_ttl}
            onChange={onChangeSelect}
          >
            {edge &&
              edge.map((item: editSelectType) => (
                <option key={item.id} value={item.value}>
                  {" "}
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <Switch
          name="loading"
          color="primary"
          checked={isCheck}
          onChange={()=> setIsCheck((prev:boolean)=>!prev)}
        />

        <CachingEditButtons handleCancel={handleCancel} handleSubmitEdit={handleSubmitEdit} />
      </div>
    </div>
  );
};

export default CachingEditSelect;
