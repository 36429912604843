import React, { useState } from "react";
import style from "../style/cachingRule.module.css";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import edit from "../../../../../../images/caching/vector.svg";
import delet from "../../../../../../images/caching/delete.svg";
import {
  RootState,
  usedispatch,
} from "../../../../../../../component/features/store/store";
import { constraintType, getAllRuleType } from "../../cachingType";
import { DeleteUlr } from "../../../../../../../component/features/slice/caching/cachingApi";
import { useSelector } from "react-redux";
import { cachingModalStyle } from "../style/cachingRuleModal";
import CachingEdit from "../../cachingEdit";
import { domain_id, token } from "../../../../../../globalState";

const CachingRuleTables = () => {

  const { getAllRule } = useSelector((state: RootState) => state.caching);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isCheckId, setIsCheckID] = useState(null);
  const dispatch = usedispatch();

  const { isLoadingCreateRule, isLoadingDelete,isLoadingEditRule } = useSelector(
    (state: RootState) => state.caching
  );

  const handleDeleteUrl = ({ id }: any) => {
    dispatch(DeleteUlr({ id, domain_id, token }));
  };

  const handleEditCachin = ({ id }: any) => {
    localStorage.setItem("page_id",id)
    setIsCheckID(id);
    setOpen(true);

  };
  return (
    <div className={style.cachingRuleTables}>
      {isLoadingCreateRule || isLoadingDelete || isLoadingEditRule ? (
        <span> Loading ...</span>
      ) : (
        getAllRule &&
          getAllRule.map((item: getAllRuleType) => (
            <div key={item.id} className={style.ruleTable}>
            <div className={style.position}>
              <span> {item.priority}</span>
            </div>

            <div className={style.urlDescription}>
              {item.targets.map((elem: constraintType) => (
                <span key={elem.constraint.value}>{elem.constraint.value}</span>
              ))}
            </div>

            <div className={style.action}>
              <div className={style.actionContainer}>
                <Switch
                  checked={loading}
                  onChange={() => setLoading(!loading)}
                  name="loading"
                  color="primary"
                />

                <div className={style.edit}>
                  <img
                    src={edit}
                    alt="edit"
                    onClick={() => handleEditCachin({ id: item.id })}
                  />

                  {isCheckId === item.id && (
                    <Modal
                      open={open}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                         <Box sx={{...cachingModalStyle}}>
                            <CachingEdit open={open} setOpen={setOpen}/>

                         </Box>



                    </Modal>
                  )}
                </div>

                <div className={style.delete}>
                  <img
                    src={delet}
                    alt="delete"
                    onClick={() => handleDeleteUrl({ id: item.id })}
                  />
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default CachingRuleTables;
