import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "../style/checkEmail.module.css";
import emails from "../../images/signup/email.png";
import { usedispatch } from "../../features/store/store";
import { VerifyCode } from "../../features/slice/regLogin/regLoginThunk/regApi";
import { ResponseDateSucess } from "../../globalInterface";
const CheckEmailText: FC = () => {
  const email:string | null = localStorage.getItem("email");
  const navigate = useNavigate();
  const sliceEmail = email && email.slice(0, 6).toString();
  const [code, setCode] = useState({
    a: "",
    b: "",
    c: "",
    d: "",
    e: "",
    f: "",
  });

  const dispatch = usedispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode({ ...code, [e.target.name]: e.target.value });
  };

  const handleContinue = () => {
    const pin = Object.values(code).join("");

    email && dispatch(VerifyCode({ pin, email }))
      .unwrap()
      .then((res: ResponseDateSucess) => {
        if (res.success){
     
          sessionStorage.setItem("pin",res.data)
          navigate("/create/newpassword")
        } 
      });
  };

  return (
    <div className={style.checkEmailText}>
      <span>Check your email for code </span>
      <p>Please enter the 6 digit code sent to {sliceEmail}***@gmail.com</p>

      <div className={style.checkEmailIcon}>
        <img src={emails} alt="email" />

        <div className={style.enter}>
          <label> Enter code</label>
          <div className={style.inputsCode}>
            <input
              type="text"
              name="a"
              value={code.a}
              maxLength={1}
              onChange={handleChange}
            />
            <input
              type="text"
              name="b"
              value={code.b}
              maxLength={1}
              onChange={handleChange}
            />
            <input
              type="text"
              name="c"
              value={code.c}
              maxLength={1}
              onChange={handleChange}
            />
            <input
              type="text"
              name="d"
              value={code.d}
              maxLength={1}
              onChange={handleChange}
            />
            <input
              type="text"
              name="e"
              value={code.e}
              maxLength={1}
              onChange={handleChange}
            />
            <input
              type="text"
              name="f"
              value={code.f}
              maxLength={1}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className={style.continueButton}>
        {" "}
        <button onClick={handleContinue}> Continue</button>
      </div>
    </div>
  );
};

export default CheckEmailText;
