import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { GetCacheBrowser } from "../../../../../component/features/slice/caching/cachingApi";
import { RootState, usedispatch } from "../../../../../component/features/store/store";
import CachingSelect from "./catchingPages/CachingSelect";
import style from "./style/caching.module.css";
import CachginRule from "./cachingRule";
import { token } from "../../../../globalState";

const Caching = () => {
  const dispatch = usedispatch();
 const {browserCache,cachLevel,edge,explicit} = useSelector((state:RootState)=>state.caching)
  
 

    
  useEffect(() => {
    dispatch(GetCacheBrowser(token));
  }, []);

  return (
    <div className={style.caching}>
      <div className={style.cachingContainer}>
        <CachingSelect browserCache={browserCache} edge={edge} cachLevel={cachLevel} explicit={explicit}/>
        
      </div>

      <CachginRule />
      
    </div>
  );
};

export default Caching;
