import React from 'react'
import { Link } from 'react-router-dom';
import { settingsPagination } from '../settingsData/settingsData';
import style from "../style/settings.module.css";
const SettingsPagination = () => {
  return (
    <div className={style.settingsPagination}>
        {
            settingsPagination.map(({link,name},index)=>(
                <div key={index}>
                        <Link to={link} className={style.linkHref}> {name} </Link>
                    </div>
            ))
        }

    </div>
  )
}

export default SettingsPagination